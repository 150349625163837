export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'simdouro',
	CLIENT_FEATURES: [
		'afi'
	],
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.353.15',
	MODULES: [
		'asset-planning',
		'gap-analysis',
		'inspections'
	],
	TIMESTAMP: '2025-01-30T21:55:12.862Z',
	COMMIT: '00cb3534a8083783044b629bd8eff443b5864824',
	BRANCH: 'master',
	API_SERVER: 'https://simdouro-api.unoplatform.io:8080',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://simdouro-api.unoplatform.io:8082',
	FILE_CONVERTER_SERVER: 'https://simdouro-api.unoplatform.io:8081',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
